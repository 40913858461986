import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuItemModel } from './menu-item.model';
import { MenuItems } from './menu-items';
import { takeUntil } from 'rxjs/operators';
import { StoreService } from '../../services/store.service';
import { Subject } from 'rxjs';
import { AppUserModel } from '../../../shared/models/appUser.model';

@Component({
  selector: 'pwc-app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  appUser: AppUserModel = {} as AppUserModel;

  items: MenuItemModel[] = new MenuItems().items;

  constructor(private storeService: StoreService) {}

  ngOnInit() {
    this.setupEventListeners();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  stopRouterLink($event: any, url: string) {
    if (url !== undefined && url === 'none') {
      $event.stopPropagation();
      $event.preventDefault();
    }
  }

  private setupEventListeners() {
    this.storeService.stateWithPropertyChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(store => {
      if (store.stateChanges.appUser) {
        this.appUser = store.stateChanges.appUser;
      }
    });
  }

  public checkAuthorized(item: any) {
    const isAuthorized: boolean = !item.roles || (this.appUser && item.roles.includes(this.appUser.roleCode));
    return isAuthorized;
  }
}
