import { EnvironmentConfigModel } from './environment-config.model';

const envClientLocation = '';
const envApiLocation = '';
const envIdpProvider = '';
const envClientId = '';

export const environmentConfigDefault: EnvironmentConfigModel = {
  applicationName: 'Tax Law - iManage',

  production: false,
  isSecure: true,

  logger: {
    logResponse: true,
    logMessage: true,
    logException: true
  },

  store: {
    trackHistory: false,
    logChanges: true
  },

  grid: {
    pageSize: 50,
    pageable: {
      buttonCount: 5,
      info: true,
      type: 'input',
      pageSizes: [20, 50, 100, 1000, 5000],
      previousNext: true
    },
    resizable: true,
    sortable: true,
    scrollable: 'scrollable',
    filterable: false,
    groupable: false,
    columnMenu: { filter: true }
  },

  baseUrl: envClientLocation,
  apiUrl: envApiLocation,

  // Api End Points
  apiEndPoints: {
    appuser: '/appuser',
    client: '/client',
    subClient: {
      subClient: '/subclient',
      lookup: 'subclient/lookup'
    },
    matterStatus: '/matterstatus',
    practiceArea: '/practicearea',
    partner: '/partner',
    manager: '/manager',
    lookupcollection: '/lookupcollection',
    matter: '/matter'
  },

  // PwC Open AM Setup Start
  issuer: envIdpProvider + '/openam/oauth2',
  loginUrl: envIdpProvider + '/openam/oauth2/authorize',
  logoutUrl: envIdpProvider + '/openam/oauth2/connect/endSession',
  postLogoutRedirectUri: envClientLocation + '/signout',
  redirect: envClientLocation,
  clientSecret: 'firewall',

  clientId: envClientId,
  oidc: true,
  responseType: 'code',
  scope: 'openid email profile cloudEmail',
  requestAccessToken: false,
  // PwC Open AM Setup End

  // Azure ApplicationInsights Start
  applicationInsight: {
    enabled: false,
    instrumentationKey: '944bac71-4ca8-4212-8915-3aee29085777',
    flushTelemetry: false
  }
  // Azure ApplicationInsights End
};
