import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'pwc-app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent {
  @Output() ignore: EventEmitter<any> = new EventEmitter();

  @Input() message = 'Not Provided!';
  @Input() serverResponse: string;

  ignoreNotification(event: Event): void {
    event.preventDefault();
    this.ignore.emit();
  }
}
