import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { StoreService } from '../../services/store.service';
import { WindowRef } from '../../services/windowref.service';
import { AppUserModel } from '../../../shared/models/appUser.model';

@Component({
  selector: 'pwc-app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  appUser: AppUserModel = {} as AppUserModel;
  disableLogin = false;
  showLogin = false;

  @Output() login = new EventEmitter();
  @Output() logout = new EventEmitter();
  @Input() title: string;

  constructor(private storeService: StoreService, private window: WindowRef) {}

  ngOnInit() {
    if (window.location.search.length) {
      setTimeout(() => {
        this.showLogin = true;
      }, 2000);
    } else {
      this.showLogin = true;
    }

    this.storeService.stateChanged.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.appUser = this.storeService.getUser();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  triggerLogout() {
    this.logout.emit();
  }

  triggerLogin() {
    this.login.emit();
    this.disableLogin = true;
  }
}
