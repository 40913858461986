import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

// Kendo UI Modules
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DropDownListModule, DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { BodyModule, ExcelModule, FilterMenuModule, GridModule, RowFilterModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { PopupModule } from '@progress/kendo-angular-popup';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { IntlModule } from '@progress/kendo-angular-intl';

@NgModule({
  declarations: [],
  imports: [
    // Angular
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    // Kendo UI
    BodyModule,
    ButtonsModule,
    DateInputsModule,
    DialogModule,
    DropDownListModule,
    DropDownsModule,
    ExcelModule,
    FilterMenuModule,
    GridModule,
    InputsModule,
    NotificationModule,
    PopupModule,
    RowFilterModule,
    TooltipModule,
    IntlModule
  ],
  exports: [
    // Angular
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    // Kendo UI
    BodyModule,
    ButtonsModule,
    DateInputsModule,
    DialogModule,
    DropDownListModule,
    DropDownsModule,
    ExcelModule,
    FilterMenuModule,
    GridModule,
    InputsModule,
    NotificationModule,
    PopupModule,
    RowFilterModule,
    TooltipModule,
    IntlModule
  ],
  providers: []
})
export class SharedModule {}
