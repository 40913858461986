<div class="no-outline">
  <block-ui>
    <div class="d-flex flex-column a-bg-f5f a-template-container">
      <!-- Header -->
      <pwc-app-header (login)="login()" (logout)="logout()" [title]="title"></pwc-app-header>

      <!-- Content -->
      <div class="a-template-content a-bg-f5f">
        <router-outlet></router-outlet>
      </div>

      <!-- Footer -->
      <pwc-app-footer [title]="title"></pwc-app-footer>
    </div>
  </block-ui>
</div>
