import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./features/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'access-denied',
    loadChildren: () => import('./features/access-denied/access-denied.module').then(m => m.AccessDeniedModule)
  },
  {
    path: 'signout',
    loadChildren: () => import('./features/signout/signout.module').then(m => m.SignoutModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./features/profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'client',
    loadChildren: () => import('./features/client/client.module').then(m => m.ClientModule)
  },
  {
    path: 'sub-client',
    loadChildren: () => import('./features/sub-client/sub-client.module').then(m => m.SubClientModule)
  },
  // {
  //   path: 'matter-status',
  //   loadChildren: () => import('./features/matter-status/matter-status.module').then(m => m.MatterStatusModule)
  // },
  {
    path: 'practice-area',
    loadChildren: () => import('./features/practice-area/practice-area.module').then(m => m.PracticeAreaModule)
  },
  {
    path: 'partner',
    loadChildren: () => import('./features/partner/partner.module').then(m => m.PartnerModule)
  },
  {
    path: 'manager',
    loadChildren: () => import('./features/manager/manager.module').then(m => m.ManagerModule)
  },
  {
    path: 'matter',
    loadChildren: () => import('./features/matter/matter.module').then(m => m.MatterModule)
  },
  {
    path: 'appuser',
    loadChildren: () => import('./features/appuser/appuser.module').then(m => m.AppUserModule)
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      // enableTracing: true,
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'ignore', // reload if you want to trigger router events
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
