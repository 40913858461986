export interface AppRoleModel {
  id: number;
  code: string;
  displayValue: string;
}

export const AppRole = {
  Admin: 'Admin',
  ViewOnly: 'ViewOnly',
  Manager: 'Manager'
};
