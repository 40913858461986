import { MenuItemModel } from './menu-item.model';
import { AppRole } from '../../../shared/models/appRole.model';

export class MenuItems {
  items: MenuItemModel[] = [
    {
      text: 'Home',
      css: 'tl-menu-item-lvl01',
      url: '/home',
      icon: 'icon-building-outline',
      items: []
    },
    {
      text: 'Client',
      css: 'tl-menu-item-lvl01',
      url: '/client',
      icon: 'icon-stewardship-outline',
      roles: [AppRole.Admin, AppRole.Manager],
      items: []
    },
    {
      text: 'Sub-Client',
      css: 'tl-menu-item-lvl01',
      url: '/sub-client',
      icon: 'icon-convert-outline',
      roles: [AppRole.Admin, AppRole.Manager],
      items: []
    },
    // {
    //   text: 'Matter Status',
    //   css: 'tl-menu-item-lvl01',
    //   url: '/matter-status',
    //   icon: 'icon-location-locked-outline',
    //   roles: [AppRole.Admin, AppRole.Manager],
    //   items: []
    // },
    {
      text: 'Practice Area',
      css: 'tl-menu-item-lvl01',
      url: '/practice-area',
      icon: ' icon-capital-outline',
      roles: [AppRole.Admin, AppRole.Manager],
      items: []
    },
    {
      text: 'Partner',
      css: 'tl-menu-item-lvl01',
      url: '/partner',
      icon: 'icon-leader-outline',
      roles: [AppRole.Admin, AppRole.Manager],
      items: []
    },
    {
      text: 'Manager',
      css: 'tl-menu-item-lvl01',
      url: '/manager',
      icon: 'icon-partership-outline',
      roles: [AppRole.Admin, AppRole.Manager],
      items: []
    },
    {
      text: 'Matter',
      css: 'tl-menu-item-lvl01',
      url: '/matter',
      icon: 'icon-table-data-outline',
      roles: [AppRole.Admin, AppRole.Manager, AppRole.ViewOnly],
      items: []
    },
    {
      text: 'Administration',
      css: 'tl-menu-item-lvl01',
      url: '/appuser',
      icon: 'icon-add-user-outline',
      roles: [AppRole.Admin],
      items: []
    }
  ];
}
