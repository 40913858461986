import { Directive, Input, OnInit } from '@angular/core';
import { TemplateRef, ViewContainerRef } from '@angular/core';
import { StoreService } from '../services/store.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Directive({ selector: '[pwcAppShowAuthenticated]' })
export class ShowAuthenticatedDirective implements OnInit {
  private unsubscribe$ = new Subject<void>();

  constructor(
    private templateRef: TemplateRef<any>,
    private storeService: StoreService,
    private viewContainer: ViewContainerRef
  ) {}

  condition: boolean;

  @Input() set pwcTlShowAuthenticated(condition: boolean) {
    this.condition = condition;
  }

  ngOnInit() {
    this.storeService.stateWithPropertyChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(store => {
      const appUser = store.state.appUser;

      // Show or hide element based on appUser authentication and input variable from directive
      if ((appUser.isAuthenticated && this.condition) || (!appUser.isAuthenticated && !this.condition)) {
        this.viewContainer.clear();
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    });
  }
}
