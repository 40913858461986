// Angular
import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

// Third Party Modules
import { OAuthModule } from 'angular-oauth2-oidc';
import { BlockUIModule } from 'ng-block-ui';
// Kendo UI
import { MenuModule } from '@progress/kendo-angular-menu';

// Application Constructs
import { WINDOW_PROVIDERS } from './services/windowref.service';

// Components
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { MenuComponent } from './layout/menu/menu.component';
import { ShowAuthenticatedDirective } from './directives/show-authenticated.directive';
import { BlockuioverlayComponent } from './layout/blockuioverlay/blockuioverlay.component';
import { TooltipComponent } from './tooltip/tooltip.component';

// Services
import { InterceptorWrapperService } from './services/interceptor-wrapper.service';
import { ErrorHandlerService } from './services/error-handler.service';

export function throwIfAlreadyLoaded(parentModule: any, moduleName: string) {
  if (parentModule) {
    const msg = `${moduleName} has already been loaded. Import Core modules in the AppModule only.`;
    throw new Error(msg);
  }
}

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    MenuComponent,
    ShowAuthenticatedDirective,
    BlockuioverlayComponent,
    TooltipComponent
  ],
  imports: [
    // Angular
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    RouterModule,
    // Third party
    OAuthModule.forRoot(),
    BlockUIModule.forRoot({
      message: '',
      template: BlockuioverlayComponent,
      delayStart: 500,
      delayStop: 1000
    }),
    MenuModule
  ],
  exports: [
    // Third Party
    BlockUIModule,
    // Local
    ShowAuthenticatedDirective,
    BlockuioverlayComponent,
    FooterComponent,
    HeaderComponent,
    MenuComponent
  ],
  providers: [
    WINDOW_PROVIDERS,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorWrapperService,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService
    }
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
