import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class HttpWrapperService {
  private readonly actionUrl: string;

  constructor(private http: HttpClient) {
    this.actionUrl = environment.apiUrl;
  }

  get<T>(url: string): Observable<T> {
    return this.http.get<T>(this.actionUrl + url);
  }

  post<T>(url: string, body: any): Observable<T> {
    return this.http.post<T>(this.actionUrl + url, body);
  }

  put<T>(url: string, body: any): Observable<T> {
    return this.http.put<T>(this.actionUrl + url, body);
  }

  delete<T>(url: string, id: string): Observable<T> {
    return this.http.delete<T>(this.actionUrl + url + '/' + id);
  }

  patch<T>(url: string, body: string): Observable<T> {
    return this.http.patch<T>(this.actionUrl + url + '/', body);
  }
}
