import { Injectable, OnDestroy } from '@angular/core';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApplicationInsightsService implements OnDestroy {
  private unsubscribe$ = new Subject<void>();
  private routerSubscription: Subscription;

  private appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: environment.applicationInsight.instrumentationKey
    }
  });

  constructor(private router: Router) {
    if (environment.applicationInsight.enabled) {
      // Initialize Application Insight
      this.appInsights.loadAppInsights();

      this.routerSubscription = this.router.events
        .pipe(
          takeUntil(this.unsubscribe$),
          filter(event => event instanceof ResolveEnd)
        )
        .subscribe((event: ResolveEnd) => {
          const activatedComponent = this.getActivatedComponent(event.state.root);
          if (activatedComponent) {
            this.logPageView(
              `${activatedComponent.name} ${this.getRouteTemplate(event.state.root)}`,
              event.urlAfterRedirects
            );
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setUserId(userId: string) {
    if (environment.applicationInsight.enabled && this.appInsights.config) {
      this.appInsights.setAuthenticatedUserContext(userId);
    }
  }

  clearUserId() {
    if (environment.applicationInsight.enabled && this.appInsights.config) {
      this.appInsights.clearAuthenticatedUserContext();
    }
  }

  logPageView(name?: string, uri?: string) {
    if (environment.applicationInsight.enabled && this.appInsights.config) {
      this.appInsights.trackPageView({ name, uri });

      if (environment.applicationInsight.flushTelemetry) {
        this.flush();
      }
    }
  }

  trackException(error: Error) {
    if (environment.applicationInsight.enabled && this.appInsights.config) {
      this.appInsights.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error
      });

      if (environment.applicationInsight.flushTelemetry) {
        this.flush();
      }
    }
  }

  trackTrace(msg: string) {
    if (environment.applicationInsight.enabled && this.appInsights.config) {
      this.appInsights.trackTrace({
        message: msg,
        severityLevel: SeverityLevel.Information
      });

      if (environment.applicationInsight.flushTelemetry) {
        this.flush();
      }
    }
  }

  private flush() {
    if (environment.applicationInsight.enabled && this.appInsights.config) {
      this.appInsights.flush(true);
    }
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }

  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';
    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }

    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }

    return path;
  }
}
