//#region Imports
import { Injectable } from '@angular/core';
import { NotificationService, Position, Animation, Type, NotificationRef } from '@progress/kendo-angular-notification';
import { TooltipComponent } from './tooltip.component';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { HttpErrorResponse } from '@angular/common/http';
//#endregion

@Injectable({
  providedIn: 'root'
})
export class TooltipService {
  constructor(private notificationService: NotificationService) {}

  private notificationPosition: Position = {
    horizontal: 'center',
    vertical: 'top'
  } as Position;

  private notificationAnimation: Animation = {
    type: 'fade',
    duration: 400
  } as Animation;

  private notificationType: Type = { style: 'none', icon: false } as Type;

  private notificationHideAfter = 2000;

  private notificationAnimationError: Animation = {
    type: 'fade',
    duration: 2000
  } as Animation;

  private notificationPositionError: Position = {
    horizontal: 'center',
    vertical: 'top'
  } as Position;

  private static populateMessage(notificationRef: NotificationRef, message: string, serverResponse: string = '') {
    if (notificationRef) {
      const notificationContent = notificationRef.content as any;
      notificationContent.instance.message = message;
      notificationContent.instance.serverResponse = serverResponse;
    }
  }

  showDefault(message: string): void {
    const notificationRef: NotificationRef = this.notificationService.show({
      content: TooltipComponent,
      hideAfter: this.notificationHideAfter,
      position: this.notificationPosition,
      animation: this.notificationAnimation,
      type: this.notificationType
    });

    TooltipService.populateMessage(notificationRef, message);
  }

  showSuccess(message: string): void {
    const notificationRef: NotificationRef = this.notificationService.show({
      content: TooltipComponent,
      hideAfter: this.notificationHideAfter,
      position: this.notificationPosition,
      animation: this.notificationAnimation,
      type: { style: 'success', icon: true }
    });

    TooltipService.populateMessage(notificationRef, message);
  }

  showWarning(message: string): void {
    const notificationRef: NotificationRef = this.notificationService.show({
      content: TooltipComponent,
      hideAfter: this.notificationHideAfter,
      position: this.notificationPosition,
      animation: this.notificationAnimation,
      type: { style: 'warning', icon: true }
    });

    TooltipService.populateMessage(notificationRef, message);
  }

  showInfo(message: string): void {
    const notificationRef: NotificationRef = this.notificationService.show({
      content: TooltipComponent,
      hideAfter: this.notificationHideAfter,
      position: this.notificationPosition,
      animation: this.notificationAnimation,
      type: { style: 'info', icon: true }
    });

    TooltipService.populateMessage(notificationRef, message);
  }

  private showErrorResponse(message: string, serverResponse: string = ''): void {
    const notificationRef: NotificationRef = this.notificationService.show({
      content: TooltipComponent,
      hideAfter: this.notificationHideAfter,
      position: this.notificationPositionError,
      animation: this.notificationAnimationError,
      type: { style: 'error', icon: true },
      closable: true
    });

    TooltipService.populateMessage(notificationRef, message, serverResponse);
  }

  showError(err: HttpErrorResponse, message: string = ''): void {
    if (err === null) {
      this.showErrorResponse(`${message}`);
    } else {
      this.showErrorResponse(` ${message} ${err.message} `, err?.error?.error);
    }
  }

  showTooltip(mouseEvent: MouseEvent, tooltipDir: TooltipDirective): void {
    const el = mouseEvent.target as HTMLElement;
    const elNodeName: string = el.nodeName;
    const elParentElement: HTMLElement = el.parentElement as HTMLElement;
    const elParentElementNodeName: string = elParentElement.nodeName;
    const offsetWidth = el.offsetWidth;
    const scrollWidth = el.scrollWidth;

    if (
      elNodeName &&
      elParentElementNodeName &&
      (elNodeName === 'TD' || elNodeName === 'TH' || elNodeName === 'SPAN' || elNodeName === 'A') &&
      (elParentElementNodeName === 'TH' || elParentElementNodeName === 'TR') &&
      // && elParentElementNodeName != 'KENDO-GRID-LIST'
      offsetWidth < scrollWidth
    ) {
      tooltipDir.toggle(el);
    } else {
      tooltipDir.hide();
    }
  }
}
