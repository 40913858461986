import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';

export const authConfig: AuthConfig = {
  issuer: environment.issuer,

  oidc: environment.oidc,
  responseType: environment.responseType,
  dummyClientSecret: environment.clientSecret,
  requestAccessToken: environment.requestAccessToken,

  loginUrl: environment.loginUrl,
  logoutUrl: environment.logoutUrl,
  postLogoutRedirectUri: environment.postLogoutRedirectUri,
  redirectUri: environment.redirect,

  clientId: environment.clientId,
  scope: environment.scope
};
