const envApiLocation = 'https://imanage-api-dev-ca.pwc.com/api';
const envClientLocation = 'https://taxlaw-dev-ca.pwc.com';
const envIdpProvider = 'https://login-stg.pwc.com:443';
const envClientId = '';

export const environment: EnvironmentConfigModel = {
  ...environmentConfigDefault,

  production: true,

  baseUrl: envClientLocation,
  apiUrl: envApiLocation,

  // PwC Open AM Setup Start
  issuer: envIdpProvider + '/openam/oauth2',
  loginUrl: envIdpProvider + '/openam/oauth2/authorize',
  logoutUrl: envIdpProvider + '/openam/oauth2/connect/endSession',
  postLogoutRedirectUri: envClientLocation + '/signout',
  redirect: envClientLocation,
  clientId: '#{clientId}#',
  clientSecret: '#{clientSecret}#',
  // PwC Open AM Setup End

  // Azure ApplicationInsights
  applicationInsight: {
    enabled: true,
    instrumentationKey: '#{instrumentationKey}#',
    flushTelemetry: false
  }
};

import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
import { EnvironmentConfigModel } from './environment-config.model';
import { environmentConfigDefault } from './environment-config-default';
