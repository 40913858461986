import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private requestInProgress$ = new BehaviorSubject(false);

  setLoading(isLoading: boolean) {
    this.requestInProgress$.next(isLoading);
  }

  getLoading(): Observable<boolean> {
    return this.requestInProgress$.asObservable();
  }
}
