import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { ObservableStore } from '@codewithdan/observable-store';

if (environment.production) {
  enableProdMode();

  if (window.console) {
    // @ts-ignore
    // Disable logging in production builds
    window.console.log = () => {};
    // @ts-ignore
    window.console.warn = () => {};
    // @ts-ignore
    window.console.error = () => {};
    // @ts-ignore
    window.console.time = () => {};
    // @ts-ignore
    window.console.timeEnd = () => {};
  }

  ObservableStore.globalSettings = { isProduction: environment.production };
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
