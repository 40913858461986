import { Injectable, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Observable, of, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { HttpWrapperService } from '../../core/services/http-wrapper.service';
import { StoreService } from '../../core/services/store.service';
import { LoggerService } from '../../core/services/logger.service';

import { environment } from '../../../environments/environment';
import { LookupCollectionModel } from '../models/lookupCollection.model';

@Injectable({
  providedIn: 'root'
})
export class LookupCollectionService implements OnDestroy {
  private unsubscribe$ = new Subject<void>();

  constructor(
    private http: HttpWrapperService,
    private loggerService: LoggerService,
    private storeService: StoreService
  ) {}

  get(): Observable<LookupCollectionModel> {
    return this.http.get<LookupCollectionModel>(environment.apiEndPoints.lookupcollection).pipe(
      takeUntil(this.unsubscribe$),
      map(
        (response: LookupCollectionModel) => {
          this.loggerService.logResponse(response);
          return response;
        },
        (err: HttpErrorResponse) => {
          this.loggerService.logException(err);
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
