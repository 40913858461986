//#region Imports

import { Component, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { AuthService } from './core/services/auth.service';
import { LoggerService } from './core/services/logger.service';
import { TooltipService } from './core/tooltip/tooltip.service';

import { AppUserModel } from './shared/models/appUser.model';
import { environment } from '../environments/environment';
import { LoaderService } from './core/services/loader.service';
import { LookupCollectionService } from './shared/services/lookup-collection.service';
import { StoreService } from './core/services/store.service';
import { ClaimsModel } from './core/models/claims.model';
import { AppRoleModel } from './shared/models/appRole.model';

//#endregion

@Component({
  selector: 'pwc-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  //#region Fields

  @BlockUI() blockUI: NgBlockUI;
  private unsubscribe$ = new Subject<void>();
  title = environment.applicationName || 'Tax Law - iManage';
  appUser: AppUserModel = {} as AppUserModel;

  //#endregion

  //#region Constructor

  constructor(
    private loggerService: LoggerService,
    private storeService: StoreService,
    private tooltipService: TooltipService,
    private authService: AuthService,
    private lookupCollectionService: LookupCollectionService,
    private loaderService: LoaderService
  ) {
    this.subscribeToEvents();
    this.authService.configure();

    this.authService
      .tryLogin()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (authenticatedUser: AppUserModel) => {
          if (authenticatedUser.isAuthenticated) {
            this.authService
              .authorize(authenticatedUser)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe((response: AppUserModel) => {
                this.appUser = response;
              });
          }
        },
        (err: HttpErrorResponse) => {
          this.loggerService.logException(err, 'Failed to authenticate user!');
          this.showError(err, 'Failed to get user!');
        }
      );
  }

  //#endregion

  //#region OnEvents

  subscribeToEvents() {
    this.loaderService.getLoading().subscribe((status: boolean) => {
      if (status) {
        this.blockUI.start('Loading ...');
      } else {
        this.blockUI.stop();
      }
    });

    this.storeService.stateWithPropertyChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(store => {
      if (!this.storeService.isEmpty(store.stateChanges.appUser)) {
        if (
          store.stateChanges?.appUser?.isAuthenticated &&
          store.stateChanges?.appUser?.roleId > 0 &&
          this.storeService.isEmpty(store.state.lookupCollection)
        ) {
          this.loadStartUpData();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  //#endregion

  //#region Methods

  private loadStartUpData() {
    forkJoin([this.lookupCollectionService.get()])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        () => {
          this.loggerService.logMessage('Done loading initial data (lookupcollection, error count');
        },
        (err: HttpErrorResponse) => {
          this.loggerService.logException(err);
          this.showError(err, 'Failed to load lookup values and remap errors!');
        }
      );
  }

  login() {
    this.authService.login();
  }

  logout() {
    this.authService.logout();
  }

  private showError(err: HttpErrorResponse, customMessage: string) {
    this.tooltipService.showError(err, customMessage);
  }

  //#endregion
}
