import { Injectable } from '@angular/core';
import { ApplicationInsightsService } from './application-insights.service';

import * as safeJsonStringify from 'safe-json-stringify';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  private messageFormatting = 'background: #222; color: #22992E; font-size: 12px;';
  private errorFormatting = 'background: #222;   color: #FF0000; font-size: 14px;';
  private responseFormatting = 'background: #222; color: #FFB600; font-size: 12px;';

  constructor(private appInsightsService: ApplicationInsightsService) {}

  private logData(item: any, message: string = '', formatting: string = '') {
    if (item) {
      console.log(item);
      this.appInsightsService.trackTrace(safeJsonStringify(item));
    }

    if (message) {
      console.log(`%c${message}`, formatting);
      this.appInsightsService.trackTrace(message);
    }
  }

  logMessage(message: string, item: any = null) {
    if (environment.logger.logMessage && !environment.production) {
      console.log('%c--------------------- Message ---------------------', this.messageFormatting);
      this.logData(item, message, this.messageFormatting);

      if (item) {
        this.appInsightsService.trackTrace(message + ': ' + safeJsonStringify(item));
      } else {
        this.appInsightsService.trackTrace(message);
      }
    }
  }

  logException(error: any, message: string = '') {
    if (environment.logger.logException) {
      console.log('%c--------------------- Exception ---------------------', this.errorFormatting);
      this.logData(error, message);
      this.appInsightsService.trackException(error);
    }
  }

  logResponse(response: any) {
    if (environment.logger.logResponse && response && !environment.production) {
      console.log('%c--------------------- Http Response ---------------------', this.responseFormatting);
      this.logData(response);
      this.appInsightsService.trackTrace(safeJsonStringify(response));
    }
  }

  logItem(response: any) {
    if (environment.logger.logResponse && response && !environment.production) {
      console.log('%c--------------------- Item ---------------------', this.messageFormatting);
      this.logData(response);
      this.appInsightsService.trackTrace(safeJsonStringify(response));
    }
  }
}
